import { defaultsDeep } from "lodash";
import { DeepNullableObj, Event } from "../types";
import { MockFn, createMock } from "./index";
import mockAccount from "./mockAccount";
import mockCareprovider from "./mockCareprovider";
import mockCareseeker from "./mockCareseeker";
import mockEventContext from "./mockEventContext";

const mockEvent: MockFn<Partial<DeepNullableObj<Event>>> = (args) =>
  defaultsDeep(
    args,
    createMock(
      "Event",
      args,
      (shallow): DeepNullableObj<Event> => ({
        __typename: "Event",
        origin: null,
        type: null,
        timestamp: null,
        user_id: null,
        patient_id: null,
        last_seens: null,
        seen_by_recipient: null,
        seen_by_recipient_timestamp: null,
        careprovider: mockCareprovider({ ...shallow("Careprovider") }),
        careseeker: mockCareseeker({ ...shallow("Careseeker") }),
        subject_account: {
          __typename: "Account",
          id: null,
          first_name: null,
          last_name: null,
          gender: null,
          account_type: null,
          title: null,
          academic_title: null,
        },
        account: mockAccount({ ...shallow("Account") }),
        context: mockEventContext({ ...shallow("EventContext") }),
      }),
    ),
  );

export default mockEvent;
