import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { handleKeyDown } from "core/model/accessibility/keyboardActions";
import LinkV2, { LinkV2Props } from "ds_legacy/components/LinkV2";

export const MenuItem = ({
  children,
  linkProps,
  onClick,
  ...props
}: MenuItemProps & { component?: "div"; linkProps?: LinkV2Props }) => {
  return (
    <MuiMenuItem
      {...props}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown({
        onConfirm: (event) => {
          onClick?.(
            event as unknown as React.MouseEvent<HTMLLIElement, MouseEvent>,
          );
        },
      })}
    >
      {linkProps ? <LinkV2 {...linkProps}>{children}</LinkV2> : children}
    </MuiMenuItem>
  );
};
