import { defaultsDeep } from "lodash";
import { DeepNullableObj, EventContext } from "../types";
import { MockFn, createMock } from "./index";

const mockEventContext: MockFn<Partial<DeepNullableObj<EventContext>>> = (
  args,
) =>
  defaultsDeep(
    args,
    createMock(
      "EventContext",
      args,
      (_shallow): DeepNullableObj<EventContext> => ({
        __typename: "EventContext",
        accepted_solutions: null,
        arrival_time: null,
        bounced_mails: {
          bounced_emails: null,
          total_bounced: null,
          total_sent: null,
        },
        capacity_date: null,
        capacity_confirmed: null,
        careprovider_name: null,
        clinic_internal_remarks: null,
        declined_reason: null,
        diff: null,
        eligibility_reject_reason: null,
        email: null,
        file_type: null,
        file: null,
        filters: null,
        mail_type: null,
        manual: null,
        message_iv: null,
        message: null,
        new_assignee_name: null,
        new_roles: null,
        new_status: null,
        no_capacity_expiration: null,
        no_capacity_reasons: null,
        old_assignee_name: null,
        onboarding_comment: null,
        onboarding_email_type: null,
        patient_type: null,
        possible_entry_date: null,
        previous_status: null,
        reason: null,
        recipients: null,
        reha_form_status: null,
        request_link: null,
        request_unavailable_reason: null,
        seald_message: null,
        stop_description: null,
        stop_reason: null,
        solution_filter_reasons: null,
        transferred_to_specialization: null, // 1
        user_id: null,
        winning_solution: null, // 1
      }),
    ),
  );

export default mockEventContext;
