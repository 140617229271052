import { defaultsDeep } from "lodash";
import { CareseekerModule, DeepNullableObj } from "../types";
import { MockFn, createMock } from "./index";

const mockCareseekerModule: MockFn<
  Partial<DeepNullableObj<CareseekerModule>>
> = (args) =>
  defaultsDeep(
    args,
    createMock(
      "CareseekerModule",
      args,
      (): DeepNullableObj<CareseekerModule> => ({
        status: 1,
        onboarding_email_link: "",
        onboarding_requirements: "",
        start_date: 0,
        onboarding_radius_km: 0,
        prio_list_activation_target: 0,
      }),
    ),
  );

export default mockCareseekerModule;
