import { BedrockPromptRequest, BedrockTranscribeRequest } from "core/types";
import { BEDROCK_LOCAL_KEY } from "dsl/organisms/BedrockACP/utils/auth";
import { tokenAccessor } from "../../transport";

const BASE_BEDROCK_URL = "https://voice.recareai.com";

export const getBedrockBaseUrl = () => {
  const isLocal = localStorage.getItem(BEDROCK_LOCAL_KEY) === "true";
  return isLocal ? "http://localhost:8080" : BASE_BEDROCK_URL;
};

class ApiError extends Error {
  public status: number;
  public responseBody?: any;

  constructor(message: string, status: number, responseBody?: any) {
    super(message);
    this.status = status;
    this.responseBody = responseBody;
    this.name = "ApiError";
  }
}

const superFetch = async <T>({
  body,
  method,
  route,
}: {
  body: BedrockTranscribeRequest | BedrockPromptRequest;
  method: "POST";
  route: string;
}): Promise<T> => {
  const url = `${getBedrockBaseUrl()}/${route}`;

  const headers = {
    Authorization: `Bearer ${tokenAccessor()}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method,
    body: JSON.stringify(body),
    headers,
  });

  if (!response.ok) {
    let responseBody;
    if (response.headers.get("content-type")?.includes("application/json")) {
      try {
        responseBody = await response.json();
      } catch (err) {
        console.warn("Failed to parse response body as JSON", err);
      }
    }

    const errorMessage =
      response.status === 404
        ? "Error 404: Not Found"
        : `${response.statusText} (${response.status})`;

    const error = new ApiError(errorMessage, response.status, responseBody);
    console.error("API Error:", error);

    throw error;
  }

  return response.json();
};

export default {
  transcribeS3({
    body,
  }: {
    body: BedrockTranscribeRequest;
  }): Promise<string | null> {
    return superFetch({
      route: `transcribe`,
      body,
      method: "POST",
    });
  },
  inference({ body }: { body: BedrockPromptRequest }): Promise<string | null> {
    return superFetch({
      route: `bedrock`,
      body,
      method: "POST",
    });
  },
};
