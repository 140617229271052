import { useState } from "react";

export const BEDROCK_LOCAL_KEY = "bedrock-is-local";

export const useBedrockLocalBackend = () => {
  const [isLocal, setIsLocal] = useState(
    localStorage.getItem(BEDROCK_LOCAL_KEY) === "true",
  );

  const handleSetIsLocal = (value: boolean) => {
    localStorage.setItem(BEDROCK_LOCAL_KEY, JSON.stringify(value));
    setIsLocal(value);
    window.location.reload();
  };

  return {
    isLocal,
    handleSetIsLocal,
  };
};
